'use client';

import { useState } from 'react';
import { ToggleContext } from '@/context';

type ToggleProviderProps = Readonly<{
  children: React.ReactNode;
}>;

const ToggleProvider = ({ children }: ToggleProviderProps) => {
  const [toggle, setToggle] = useState('all-applications');
  return (
    <ToggleContext.Provider value={{ toggle, setToggle }}>
      {children}
    </ToggleContext.Provider>
  );
};

export default ToggleProvider;
