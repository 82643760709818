'use client';

import { useState } from 'react';
import { AuthContext } from '@/context';

type AuthProviderProps = Readonly<{
  children: React.ReactNode;
}>;

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState(null);
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
