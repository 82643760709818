import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/home/runner/work/portal-ux/portal-ux/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["AppDetailsHeader"] */ "/home/runner/work/portal-ux/portal-ux/src/components/display-page/app-details-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InsightsPage"] */ "/home/runner/work/portal-ux/portal-ux/src/components/display-page/insights/insights-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PromptsPage"] */ "/home/runner/work/portal-ux/portal-ux/src/components/display-page/prompts/prompts-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionDetails"] */ "/home/runner/work/portal-ux/portal-ux/src/components/display-page/session-details.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/runner/work/portal-ux/portal-ux/src/components/theme-provider/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/portal-ux/portal-ux/src/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageProvider"] */ "/home/runner/work/portal-ux/portal-ux/src/providers/LanguageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/portal-ux/portal-ux/src/providers/ToggleProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/portal-ux/portal-ux/src/providers/TourGuideProvider.tsx");
