/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useState } from 'react';
import { TourContext } from '@/context';

type TourGuideProviderProps = Readonly<{
  children: React.ReactNode;
}>;

const TourGuideProvider = ({ children }: TourGuideProviderProps) => {
  // set the value to true if the guided tour starts by default
  const [startTour, setStartTour] = useState(false);
  return (
    <TourContext.Provider value={{ startTour, setStartTour }}>
      {children}
    </TourContext.Provider>
  );
};

export default TourGuideProvider;
